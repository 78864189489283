import { ElMessage, ElLoading } from 'element-plus';
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/loading/style/css'

import { mixins } from "@/plugins/mixins";
export default {
  name: "dictionaryEdit",
  components: {},
  mixins: [mixins],
  data() {
    return {
      formModel: {
        id: null,
        code: null,
        name: null,
        remark: null
      },
      formRules: {
        code: [{
          required: true,
          message: '请输入字典分类编码',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '请输入字典分类名称',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    submitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const callBack = res => {
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$router.back();
            } else {
              ElMessage.error(res.message);
            }
          };
          if (this.edit) {
            this.$api.business.dictionary.update(this.formModel).then(callBack);
          }
        }
      });
    }
  },
  beforeMount() {
    if (this.$route.query.id) {
      this.edit = true;
      const loading = ElLoading.service();
      this.$api.business.dictionary.detail({
        id: this.$route.query.id
      }).then(res => {
        if (res.code === 200) {
          this.formModel = Object.assign(this.formModel, res.data);
        } else if (res.code === 112402) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res.message);
          this.$router.back();
        }
      }).finally(() => {
        loading.close();
      });
    }
  }
};